import GC from "@grapecity/spread-sheets";
import React, { useEffect, useState } from "react";
import { SpreadSheets, Worksheet } from "@grapecity/spread-sheets-react";
import * as constants from "../constants";

export const Spreadsheet = (props) => {
    const [spreadArr, setSpreadArr] = useState([]);
    const sheetCount = props.eodSheetCount;

    let nonRedPmeSheet;
    let nonRedSmeSheet;
    let T2MethanolFOB;
    let fobHouston;
    let usVsEurEth;
    let phyEvengSheet;
    let pmeDiscountSheet;
    let ucommSheetOne = null,
        ucommSheetTwo = null,
        ucommSheetThree = null,
        ucommSheetFour = null,
        ucommSheetFive = null;

    const [phyEvengFlag, setPhyEvengFlag] = useState(false);
    const [ucomFLag, setucomFLag] = useState(false);

    const flag = true;
    const [uComeVal, setUComeVal] = useState("");
    const [discountVal, setDiscountVal] = useState("");
    let count = 0;

    window.EurUsDValue = props.EurUsDValue;
    window.twtgalValue = props.twtgalValue;

    useEffect(() => {
        if (props.ucommFormula) {
            props.onLoad(
                true,
                spreadArr,
                props.eodSheetDate,
                props.eodSheetName,
                "UcommFormulaRows",
                "",
                ""
            );
            props.disableUcombtn();
            setucomFLag(true);
        }
        if (props.addPremiumCLicked) {
            props.onLoad(
                true,
                spreadArr,
                props.eodSheetDate,
                props.eodSheetName,
                "addPremium",
                props.physicalTextBoxValue,
                ""
            );
            props.changepreiumclicked();
        } else if (props.nonRedPremiumUpdate) {
            props.onLoad(
                true,
                spreadArr,
                props.eodSheetDate,
                props.eodSheetName,
                "addNonRedPremium",
                props.nonRedPremTextBoxValue,
                ""
            );

            props.changeNonRedPremiumUpdate();
        } else if (props.fxRateUpdate) {
            props.onLoad(
                true,
                spreadArr,
                props.eodSheetDate,
                props.eodSheetName,
                "addFxRate",
                props.gasOilTextBoxValue,
                props.fxRateTextBoxValue
            );

            props.changeFxRateUpdate();
        } else if (props.gasOilUpdate) {
            props.onLoad(
                true,
                spreadArr,
                props.eodSheetDate,
                props.eodSheetName,
                "addGasOil",
                props.gasOilTextBoxValue,
                props.fxRateTextBoxValue
            );

            props.changeGasOilUpdate();
        } else if (phyEvengFlag) {
            props.onLoad(
                true,
                spreadArr,
                props.eodSheetDate,
                props.eodSheetName,
                "phyEvengSheetUpdate",
                props.gasOilTextBoxValue + " " + props.fxRateTextBoxValue,
                uComeVal + " " + discountVal
            );

            setPhyEvengFlag(false);
        } else if (props.eurUsdFlag) {
            props.onLoad(
                true,
                spreadArr,
                props.eodSheetDate,
                props.eodSheetName,
                "usMethanolEurUsdUpdate",
                window.EurUsDValue,
                ""
            );
            props.disableEurUsdFlag();
        } else if (props.twtgalFlag) {
            props.onLoad(
                true,
                spreadArr,
                props.eodSheetDate,
                props.eodSheetName,
                "usMethanolTwtGalUpdate",
                window.twtgalValue,
                ""
            );
            props.disableTwtGalFlag();
        } else {
            props.onLoad(true, spreadArr, props.eodSheetDate, props.eodSheetName, "", "", "");
        }
    });
    const height = window.innerHeight;
    const hwidth = window.innerWidth;
    const sheetWidth = hwidth / 2 - hwidth / 50;
    const applyFormulas = (sheet, args, rowNumber, col) => {
        if (props.eodSheetName === constants.sheetNames.UCOMM) {
            sheet.recalcAll(true);
            ucommSheetFour.recalcAll(true);

            if (ucommSheetFour.hasFormula(rowNumber, 2, GC.Spread.Sheets.SheetArea.viewport)) {
                ucommSheetFour.setFormula(
                    rowNumber,
                    2,
                    ucommSheetFour.getValue(rowNumber, 1, GC.Spread.Sheets.SheetArea.viewport) +
                        "+(" +
                        ucommSheetThree.getValue(
                            rowNumber,
                            3,
                            GC.Spread.Sheets.SheetArea.viewport
                        ) +
                        "+" +
                        ucommSheetTwo.getValue(rowNumber, 3, GC.Spread.Sheets.SheetArea.viewport) +
                        "+" +
                        ucommSheetOne.getValue(rowNumber, 3, GC.Spread.Sheets.SheetArea.viewport) +
                        ")/2",
                    GC.Spread.Sheets.SheetArea.viewport
                );
            }

            if (sheet.name() === props.eodSheetData.sheet1_datapoint_name) {
                if (rowNumber === 0) {
                    const value =
                        ucommSheetOne.getValue(
                            rowNumber,
                            col,
                            GC.Spread.Sheets.SheetArea.viewport
                        ) +
                        ucommSheetTwo.getValue(
                            rowNumber,
                            col,
                            GC.Spread.Sheets.SheetArea.viewport
                        ) +
                        ucommSheetThree.getValue(
                            rowNumber,
                            col,
                            GC.Spread.Sheets.SheetArea.viewport
                        );
                    ucommSheetFive.setValue(rowNumber, col, value);
                    const val =
                        ucommSheetOne.getValue(
                            rowNumber,
                            col,
                            GC.Spread.Sheets.SheetArea.viewport
                        ) +
                        ucommSheetTwo.getValue(
                            rowNumber + 1,
                            col,
                            GC.Spread.Sheets.SheetArea.viewport
                        ) +
                        ucommSheetThree.getValue(
                            rowNumber + 1,
                            col,
                            GC.Spread.Sheets.SheetArea.viewport
                        );
                    ucommSheetFive.setValue(rowNumber + 1, col, val);
                } else if (rowNumber > 1) {
                    const value =
                        ucommSheetOne.getValue(
                            rowNumber,
                            col,
                            GC.Spread.Sheets.SheetArea.viewport
                        ) +
                        ucommSheetTwo.getValue(
                            rowNumber,
                            col,
                            GC.Spread.Sheets.SheetArea.viewport
                        ) +
                        ucommSheetThree.getValue(
                            rowNumber,
                            col,
                            GC.Spread.Sheets.SheetArea.viewport
                        );
                    ucommSheetFive.setValue(rowNumber, col, value);
                }
            } else if (
                sheet.name() === props.eodSheetData.sheet2_datapoint_name ||
                sheet.name() === props.eodSheetData.sheet3_datapoint_name
            ) {
                let value;
                if (rowNumber < 2) {
                    value =
                        ucommSheetOne.getValue(0, col, GC.Spread.Sheets.SheetArea.viewport) +
                        ucommSheetTwo.getValue(
                            rowNumber,
                            col,
                            GC.Spread.Sheets.SheetArea.viewport
                        ) +
                        ucommSheetThree.getValue(
                            rowNumber,
                            col,
                            GC.Spread.Sheets.SheetArea.viewport
                        );
                } else {
                    value =
                        ucommSheetOne.getValue(
                            rowNumber,
                            col,
                            GC.Spread.Sheets.SheetArea.viewport
                        ) +
                        ucommSheetTwo.getValue(
                            rowNumber,
                            col,
                            GC.Spread.Sheets.SheetArea.viewport
                        ) +
                        ucommSheetThree.getValue(
                            rowNumber,
                            col,
                            GC.Spread.Sheets.SheetArea.viewport
                        );
                }
                ucommSheetFive.setValue(rowNumber, col, value);
            }
            sheet.setFormula(
                rowNumber,
                3,
                `C${rowNumber + 1}-B${rowNumber + 1}`,
                GC.Spread.Sheets.SheetArea.viewport
            );
            return true;
        }

        if (
            sheet.name() === constants.sheetNames.NONREDPME ||
            sheet.name() === constants.sheetNames.NONREDSME ||
            sheet.name() === constants.sheetNames.NONREDFAME0 ||
            sheet.name() === constants.sheetNames.NONREDRME
        ) {
            sheet.setFormula(
                rowNumber,
                3,
                "IF(C" +
                    (rowNumber + 1) +
                    "=0,SUM(B" +
                    (rowNumber + 1) +
                    ",E" +
                    (rowNumber + 1) +
                    "),C" +
                    (rowNumber + 1) +
                    ")",
                GC.Spread.Sheets.SheetArea.viewport
            );
        } else if (props.eodSheetName !== constants.sheetNames.PHYSICALEVENINGNUMBERS) {
            sheet.setFormula(
                rowNumber,
                3,
                "IFERROR(AVERAGE(B" + (rowNumber + 1) + ":C" + (rowNumber + 1) + ")," + '""' + ")",
                GC.Spread.Sheets.SheetArea.viewport
            );
        } else if (props.eodSheetName === constants.sheetNames.PHYSICALEVENINGNUMBERS) {
            if (
                sheet.name() === constants.sheetNames.PHYSICALEVENINGEUBIOFUELS ||
                sheet.name() === constants.sheetNames.PMEOVERFME
            ) {
                const physicalPremGasoilVal = parseFloat(phyEvengSheet.getValue(3, 2));
                const spotVal = parseFloat(pmeDiscountSheet.getValue(0, 1));
                setDiscountVal(spotVal.toString());
                setUComeVal(physicalPremGasoilVal.toString());
                setPhyEvengFlag(true);
            }
        }

        if (sheet.name() === constants.sheetNames.T2METHANOLFOBROTTERDAM) {
            if (col === 1 || col === 2) {
                const tag = T2MethanolFOB.getTag(
                    rowNumber,
                    col,
                    GC.Spread.Sheets.SheetArea.viewport
                );

                if (tag === "true") {
                    const value0 =
                        sheet.getValue(rowNumber, col, GC.Spread.Sheets.SheetArea.viewport) *
                        window.EurUsDValue;
                    T2MethanolFOB.setValue(rowNumber, col, value0);
                }
                usVsEurEth.setFormula(
                    rowNumber,
                    1,
                    "IF(AND(" +
                        T2MethanolFOB.getValue(rowNumber, 3, GC.Spread.Sheets.SheetArea.viewport) +
                        ">0," +
                        fobHouston.getValue(rowNumber, 3, GC.Spread.Sheets.SheetArea.viewport) +
                        ">0)," +
                        fobHouston.getValue(rowNumber, 3, GC.Spread.Sheets.SheetArea.viewport) +
                        "-" +
                        T2MethanolFOB.getValue(rowNumber, 3, GC.Spread.Sheets.SheetArea.viewport) +
                        ",0)",
                    GC.Spread.Sheets.SheetArea.viewport
                );
            }
        }
        if (sheet.name() === constants.sheetNames.FOBHOUSTONMETHANOL) {
            if (col === 1 || col === 2) {
                const tag = fobHouston.getTag(rowNumber, col, GC.Spread.Sheets.SheetArea.viewport);

                if (tag === "true") {
                    const value0 =
                        sheet.getValue(rowNumber, col, GC.Spread.Sheets.SheetArea.viewport) *
                        window.twtgalValue;
                    fobHouston.setValue(rowNumber, col, value0);
                }
                usVsEurEth.setFormula(
                    rowNumber,
                    1,
                    "IF(AND(" +
                        T2MethanolFOB.getValue(rowNumber, 3, GC.Spread.Sheets.SheetArea.viewport) +
                        ">0," +
                        fobHouston.getValue(rowNumber, 3, GC.Spread.Sheets.SheetArea.viewport) +
                        ">0)," +
                        fobHouston.getValue(rowNumber, 3, GC.Spread.Sheets.SheetArea.viewport) +
                        "-" +
                        T2MethanolFOB.getValue(rowNumber, 3, GC.Spread.Sheets.SheetArea.viewport) +
                        ",0)",
                    GC.Spread.Sheets.SheetArea.viewport
                );
            }
        }

        if (sheet.name() === constants.sheetNames.T2METHANOLFOB) {
            sheet.setTag(rowNumber, args.col, "false");

            sheet.recalcAll(true);
            usVsEurEth.setFormula(
                rowNumber,
                1,
                "IF(AND(" +
                    sheet.getValue(rowNumber, 3, GC.Spread.Sheets.SheetArea.viewport) +
                    ">0," +
                    fobHouston.getValue(rowNumber, 3, GC.Spread.Sheets.SheetArea.viewport) +
                    ">0)," +
                    fobHouston.getValue(rowNumber, 3, GC.Spread.Sheets.SheetArea.viewport) +
                    "-" +
                    sheet.getValue(rowNumber, 3, GC.Spread.Sheets.SheetArea.viewport) +
                    ",0)",
                GC.Spread.Sheets.SheetArea.viewport
            );
        }
        if (sheet.name() === constants.sheetNames.FOBHOUSTON) {
            sheet.setTag(rowNumber, args.col, "false");

            //used to get the updated data from the sheet
            sheet.recalcAll(true);
            usVsEurEth.setFormula(
                rowNumber,
                1,
                "IF(AND(" +
                    sheet.getValue(rowNumber, 3, GC.Spread.Sheets.SheetArea.viewport) +
                    ">0," +
                    T2MethanolFOB.getValue(rowNumber, 3, GC.Spread.Sheets.SheetArea.viewport) +
                    ">0)," +
                    sheet.getValue(rowNumber, 3, GC.Spread.Sheets.SheetArea.viewport) +
                    "-" +
                    T2MethanolFOB.getValue(rowNumber, 3, GC.Spread.Sheets.SheetArea.viewport) +
                    ",0)",
                GC.Spread.Sheets.SheetArea.viewport
            );
        }

        if (sheet.name() === constants.sheetNames.REDPME) {
            sheet.setFormula(
                rowNumber,
                3,
                "IF(C" +
                    (rowNumber + 1) +
                    "=0,SUM(B" +
                    (rowNumber + 1) +
                    ",E" +
                    (rowNumber + 1) +
                    "),C" +
                    (rowNumber + 1) +
                    ")",
                GC.Spread.Sheets.SheetArea.viewport
            );

            const tag = nonRedPmeSheet.getTag(rowNumber, 1, GC.Spread.Sheets.SheetArea.viewport);

            if (tag === "true") {
                nonRedPmeSheet.setValue(
                    rowNumber,
                    1,
                    sheet.getValue(rowNumber, 3, GC.Spread.Sheets.SheetArea.viewport)
                );

                nonRedPmeSheet.setFormula(
                    rowNumber,
                    3,
                    "IF(C" +
                        (rowNumber + 1) +
                        "=0,SUM(B" +
                        (rowNumber + 1) +
                        ",E" +
                        (rowNumber + 1) +
                        "),C" +
                        (rowNumber + 1) +
                        ")",
                    GC.Spread.Sheets.SheetArea.viewport
                );
            }
        }

        if (sheet.name() === constants.sheetNames.REDSME) {
            sheet.setFormula(
                rowNumber,
                3,
                "IF(C" +
                    (rowNumber + 1) +
                    "=0,SUM(B" +
                    (rowNumber + 1) +
                    ",E" +
                    (rowNumber + 1) +
                    "),C" +
                    (rowNumber + 1) +
                    ")",
                GC.Spread.Sheets.SheetArea.viewport
            );
            const tag = nonRedSmeSheet.getTag(rowNumber, 1, GC.Spread.Sheets.SheetArea.viewport);

            if (tag === "true") {
                nonRedSmeSheet.setValue(
                    rowNumber,
                    1,
                    sheet.getValue(rowNumber, 3, GC.Spread.Sheets.SheetArea.viewport)
                );

                nonRedSmeSheet.setFormula(
                    rowNumber,
                    3,
                    "IF(C" +
                        (rowNumber + 1) +
                        "=0,SUM(B" +
                        (rowNumber + 1) +
                        ",E" +
                        (rowNumber + 1) +
                        "),C" +
                        (rowNumber + 1) +
                        ")",
                    GC.Spread.Sheets.SheetArea.viewport
                );
            }
        }
        if (sheet.name() === constants.sheetNames.NONREDPME && args.col === 1) {
            sheet.setFormula(
                rowNumber,
                3,
                "IF(C" +
                    (rowNumber + 1) +
                    "=0,SUM(B" +
                    (rowNumber + 1) +
                    ",E" +
                    (rowNumber + 1) +
                    "),C" +
                    (rowNumber + 1) +
                    ")",
                GC.Spread.Sheets.SheetArea.viewport
            );

            sheet.setTag(rowNumber, 1, "false");
        }

        if (sheet.name() === constants.sheetNames.NONREDSME && args.col === 1) {
            sheet.setFormula(
                rowNumber,
                3,
                "IF(C" +
                    (rowNumber + 1) +
                    "=0,SUM(B" +
                    (rowNumber + 1) +
                    ",E" +
                    (rowNumber + 1) +
                    "),C" +
                    (rowNumber + 1) +
                    ")",
                GC.Spread.Sheets.SheetArea.viewport
            );

            sheet.setTag(rowNumber, 1, "false");
        }
    };
    const addHeaders = (sheetShape, count) => {
        if (
            props.eodSheetName !== constants.sheetNames.PHYSICALEVENINGNUMBERS &&
            props.eodSheetName !== constants.sheetNames.EUCARBONCREDIT &&
            props.eodSheetName !== constants.sheetNames.USCARBONCREDIT
        ) {
            sheetShape.setValue(1, 0, "Month", GC.Spread.Sheets.SheetArea.colHeader);
        }

        if (props.eodSheetName === constants.sheetNames.EODUSMETHANOL) {
            if (count === 1 || count === 4) {
                sheetShape.setValue(
                    0,
                    0,
                    "FOB Houston Methanol (¢/gal)",
                    GC.Spread.Sheets.SheetArea.colHeader
                );
            } else if (count === 2) {
                sheetShape.setValue(
                    0,
                    0,
                    "T2 Methanol FOB Rotterdam EUR/mt",
                    GC.Spread.Sheets.SheetArea.colHeader
                );
            } else if (count === 3) {
                sheetShape.setValue(
                    0,
                    0,
                    "T2 Methanol FOB Rotterdam USD/mt",
                    GC.Spread.Sheets.SheetArea.colHeader
                );
            } else if (count === 5) {
                sheetShape.setValue(
                    0,
                    0,
                    "US/EU Spread $/MT",
                    GC.Spread.Sheets.SheetArea.colHeader
                );
            }
        }
        if (props.eodSheetName === constants.sheetNames.UCOMM) {
            const header =
                count === 1
                    ? constants.sheetNames.UCOMMSHEETONE
                    : count === 2
                    ? constants.sheetNames.UCOMMSHEETTWO
                    : count === 3
                    ? constants.sheetNames.UCOMMSHEETTHREE
                    : count === 4
                    ? constants.sheetNames.UCOMMSHEETFOUR
                    : constants.sheetNames.UCOMMSHEETFIVE;
            sheetShape.setValue(0, 0, header, GC.Spread.Sheets.SheetArea.colHeader);

            sheetShape.setValue(2, 0, "", GC.Spread.Sheets.SheetArea.colHeader);
            sheetShape.setValue(
                2,
                1,
                props.eodSheetData[`sheet${count}_prev_date`],
                GC.Spread.Sheets.SheetArea.colHeader
            );
            sheetShape.setValue(
                2,
                2,
                props.fetchUcommInputDate,
                GC.Spread.Sheets.SheetArea.colHeader
            );
            sheetShape.setValue(2, 3, "Diff", GC.Spread.Sheets.SheetArea.colHeader);
            sheetShape.setValue(2, 4, "", GC.Spread.Sheets.SheetArea.colHeader);

            return true;
        }

        if (props.eodSheetData[`sheet${count}_datapoint_name`] === "US VS EURETH") {
            sheetShape.setValue(2, 0, "", GC.Spread.Sheets.SheetArea.colHeader);
            sheetShape.setValue(2, 1, "Mid", GC.Spread.Sheets.SheetArea.colHeader);
            sheetShape.setValue(2, 2, "", GC.Spread.Sheets.SheetArea.colHeader);

            if (window.innerHeight < 1500) {
                sheetShape.setColumnWidth(2, sheetWidth / 20);
            }
        } else if (props.eodSheetName === constants.sheetNames.PAPEREVENINGNUMBERS && count > 7) {
            sheetShape.setValue(2, 0, "", GC.Spread.Sheets.SheetArea.colHeader);
            sheetShape.setValue(2, 1, "Fame0Ref", GC.Spread.Sheets.SheetArea.colHeader);
            sheetShape.setValue(2, 2, "Adj", GC.Spread.Sheets.SheetArea.colHeader);
            sheetShape.setValue(2, 3, "Mid", GC.Spread.Sheets.SheetArea.colHeader);
            sheetShape.setValue(2, 4, "Prem", GC.Spread.Sheets.SheetArea.colHeader);

            if (
                props.eodSheetData[`sheet${count}_datapoint_name`] === constants.sheetNames.REDPME
            ) {
                sheetShape.setValue(2, 4, "DISC", GC.Spread.Sheets.SheetArea.colHeader);
            } else if (
                props.eodSheetData[`sheet${count}_datapoint_name`] === constants.sheetNames.REDSME
            ) {
                sheetShape.setValue(2, 4, "Prem", GC.Spread.Sheets.SheetArea.colHeader);
            } else if (
                props.eodSheetData[`sheet${count}_datapoint_name`] ===
                constants.sheetNames.NONREDFAME0
            ) {
                sheetShape.setValue(2, 1, "Fame0Ref", GC.Spread.Sheets.SheetArea.colHeader);
            } else {
                sheetShape.setValue(2, 1, "Ref", GC.Spread.Sheets.SheetArea.colHeader);
            }
        } else if (props.eodSheetName === constants.sheetNames.PHYSICALEVENINGNUMBERS) {
            if (
                props.eodSheetData[`sheet${count}_datapoint_name`] ===
                constants.sheetNames.SMEPREMFME
            ) {
                sheetShape.setValue(2, 0, "", GC.Spread.Sheets.SheetArea.colHeader);
                sheetShape.setValue(2, 1, "PREMIUM", GC.Spread.Sheets.SheetArea.colHeader);
                sheetShape.setValue(2, 2, "", GC.Spread.Sheets.SheetArea.colHeader);
                sheetShape.setValue(2, 3, "", GC.Spread.Sheets.SheetArea.colHeader);
                if (window.innerHeight < 1500) {
                    sheetShape.setColumnWidth(0, sheetWidth / 3);
                    sheetShape.setColumnWidth(1, sheetWidth / 3);
                    sheetShape.setColumnWidth(2, sheetWidth / 9);
                }
            } else if (
                props.eodSheetData[`sheet${count}_datapoint_name`] ===
                constants.sheetNames.PMEOVERFME
            ) {
                sheetShape.setValue(2, 0, "", GC.Spread.Sheets.SheetArea.colHeader);
                sheetShape.setValue(2, 1, "DISCOUNT", GC.Spread.Sheets.SheetArea.colHeader);
                sheetShape.setValue(2, 2, "", GC.Spread.Sheets.SheetArea.colHeader);
                sheetShape.setValue(2, 3, "", GC.Spread.Sheets.SheetArea.colHeader);
                if (window.innerHeight < 1500) {
                    sheetShape.setColumnWidth(0, sheetWidth / 3);
                    sheetShape.setColumnWidth(1, sheetWidth / 3);
                    sheetShape.setColumnWidth(2, sheetWidth / 9);
                }
            } else if (
                props.eodSheetData[`sheet${count}_datapoint_name`] ===
                constants.sheetNames.PHYSICALEVENINGEUBIOFUELS
            ) {
                sheetShape.setValue(2, 0, "PRODUCT", GC.Spread.Sheets.SheetArea.colHeader);
                sheetShape.setValue(2, 1, "SCBTICKER", GC.Spread.Sheets.SheetArea.colHeader);
                sheetShape.setValue(2, 2, "MID", GC.Spread.Sheets.SheetArea.colHeader);
                sheetShape.setValue(2, 3, "", GC.Spread.Sheets.SheetArea.colHeader);

                if (window.innerHeight <= 1500) {
                    sheetShape.setColumnWidth(0, sheetWidth / 1.7);
                    sheetShape.setColumnWidth(1, sheetWidth / 1.85);
                    sheetShape.setColumnWidth(2, sheetWidth / 5.8);
                    sheetShape.setColumnWidth(3, sheetWidth / 9);
                }
            }
        } else if (
            props.eodSheetName === constants.sheetNames.EUCARBONCREDIT ||
            props.eodSheetName === constants.sheetNames.USCARBONCREDIT
        ) {
            sheetShape.setValue(2, 0, "", GC.Spread.Sheets.SheetArea.colHeader);
            sheetShape.setValue(2, 1, "Mid", GC.Spread.Sheets.SheetArea.colHeader);
            if (window.innerWidth < 1500) {
                sheetShape.setColumnWidth(0, sheetWidth / 2.5);
                sheetShape.setColumnWidth(1, sheetWidth / 3.5);
            } else {
                sheetShape.setColumnWidth(0, sheetWidth / 3);
                sheetShape.setColumnWidth(1, sheetWidth / 3);
            }
        } else {
            sheetShape.setValue(2, 0, "", GC.Spread.Sheets.SheetArea.colHeader);
            sheetShape.setValue(2, 1, "Bid", GC.Spread.Sheets.SheetArea.colHeader);
            sheetShape.setValue(2, 2, "Offer", GC.Spread.Sheets.SheetArea.colHeader);
            sheetShape.setValue(2, 3, "Mid", GC.Spread.Sheets.SheetArea.colHeader);
            sheetShape.setValue(2, 4, "", GC.Spread.Sheets.SheetArea.colHeader);
        }
    };
    const SheetOptions = (spread, sheetShape, coloumnCount) => {
        //making the sheet protected
        sheetShape.options.isProtected = true;
        //hiding the rows and coloumn headers
        sheetShape.options.rowHeaderVisible = false;
        sheetShape.options.columnHeaderVisible = false;

        //setting column count of spreadsheet
        sheetShape.setColumnCount(coloumnCount);

        //setting spreadsheet options values
        spread.options.showVerticalScrollbar = false;
        spread.options.showHorizontalScrollbar = false;

        spread.options.tabStripVisible = false;
        spread.options.tabEditable = false;
        spread.options.newTabVisible = false;
        spread.options.tabStripRatio = 1;

        sheetShape.options.clipBoardOptions = GC.Spread.Sheets.ClipboardPasteOptions.values;
    };
    const addSpans = (sheetShape, coloumnCount) => {
        sheetShape.addSpan(0, 0, 1, coloumnCount, GC.Spread.Sheets.SheetArea.colHeader);
        sheetShape.addSpan(1, 0, 1, 1, GC.Spread.Sheets.SheetArea.colHeader);

        sheetShape.addSpan(2, 0, 1, 1, GC.Spread.Sheets.SheetArea.colHeader);
        sheetShape.addSpan(2, 1, 1, 1, GC.Spread.Sheets.SheetArea.colHeader);
        sheetShape.addSpan(2, 2, 1, 1, GC.Spread.Sheets.SheetArea.colHeader);
        sheetShape.addSpan(2, 3, 1, 1, GC.Spread.Sheets.SheetArea.colHeader);
        sheetShape.addSpan(2, 4, 1, 1, GC.Spread.Sheets.SheetArea.colHeader);

        if (
            props.eodSheetData[`sheet${count}_datapoint_name`] ===
            constants.sheetNames.PHYSICALEVENINGEUBIOFUELS
        ) {
            sheetShape.addSpan(1, 1, 1, 2, GC.Spread.Sheets.SheetArea.colHeader);
        } else if (
            props.eodSheetData[`sheet${count}_datapoint_name`] === "US VS EURETH" ||
            props.eodSheetData[`sheet${count}_datapoint_name`] ===
                constants.sheetNames.PMEOVERFME ||
            props.eodSheetData[`sheet${count}_datapoint_name`] === constants.sheetNames.SMEPREMFME
        ) {
            //
        } else if (
            props.eodSheetName === constants.sheetNames.EUCARBONCREDIT ||
            props.eodSheetName === constants.sheetNames.USCARBONCREDIT
        ) {
            sheetShape.addSpan(1, 0, 1, coloumnCount, GC.Spread.Sheets.SheetArea.colHeader);
        } else {
            sheetShape.addSpan(1, 1, 1, 3, GC.Spread.Sheets.SheetArea.colHeader);
        }
    };
    const setFlagImage = (sheetShape, coloumnCount, count) => {
        const flagName = props.eodSheetData[`sheet${count}_datapoint`] + "_flag";
        if (props.eodSheetData[flagName][0].IMAGE !== "") {
            if (
                props.eodSheetData[`sheet${count}_datapoint_name`] ===
                constants.sheetNames.PHYSICALEVENINGEUBIOFUELS
            ) {
                sheetShape
                    .getRange(1, 3, 1, coloumnCount, GC.Spread.Sheets.SheetArea.colHeader)
                    .backgroundImage(
                        `data:image/jpeg;base64,${props.eodSheetData[flagName][0].IMAGE}`
                    )
                    .width(30);
            } else if (
                props.eodSheetData[`sheet${count}_datapoint_name`] === "US VS EURETH" ||
                props.eodSheetData[`sheet${count}_datapoint_name`] ===
                    constants.sheetNames.PMEOVERFME ||
                props.eodSheetData[`sheet${count}_datapoint_name`] ===
                    constants.sheetNames.SMEPREMFME
            ) {
                sheetShape
                    .getRange(1, 2, 1, coloumnCount, GC.Spread.Sheets.SheetArea.colHeader)
                    .backgroundImage(
                        `data:image/jpeg;base64,${props.eodSheetData[flagName][0].IMAGE}`
                    )
                    .width(30);
            } else {
                sheetShape
                    .getRange(1, 4, 1, coloumnCount, GC.Spread.Sheets.SheetArea.colHeader)
                    .backgroundImage(
                        `data:image/jpeg;base64,${props.eodSheetData[flagName][0].IMAGE}`
                    )
                    .width(40);
            }
        }
    };
    const formatSheet = (sheetShape, coloumnCount) => {
        sheetShape
            .getRange(0, 0, 1, coloumnCount, GC.Spread.Sheets.SheetArea.colHeader)
            .font("bold 10pt arial")
            .locked(true)
            .backColor("black")
            .foreColor("white")
            .hAlign(GC.Spread.Sheets.HorizontalAlign.center);

        if (
            props.eodSheetName === constants.sheetNames.PAPEREVENINGNUMBERS &&
            (count === 1 || count === 7 || count === 9)
        ) {
            sheetShape
                .getRange(0, 0, 1, coloumnCount, GC.Spread.Sheets.SheetArea.colHeader)
                .font("bold 10pt arial")
                .locked(true)
                .backColor("black")
                .foreColor("red")
                .hAlign(GC.Spread.Sheets.HorizontalAlign.center);
        } else if (props.eodSheetName === constants.sheetNames.PAPEREVENINGNUMBERS && count > 9) {
            sheetShape
                .getRange(0, 0, 1, coloumnCount, GC.Spread.Sheets.SheetArea.colHeader)
                .font("bold 10pt arial")
                .locked(true)
                .backColor("black")
                .foreColor("green")
                .hAlign(GC.Spread.Sheets.HorizontalAlign.center);
        }
        if (props.eodSheetName === constants.sheetNames.PHYSICALEVENINGNUMBERS && count === 1) {
            sheetShape
                .getRange(1, 1, 1, 3, GC.Spread.Sheets.SheetArea.colHeader)
                .font("bold 10pt arial")
                .backColor("#120e52")
                .foreColor("#120e52");
            sheetShape
                .getRange(1, 0, 1, 1, GC.Spread.Sheets.SheetArea.colHeader)
                .font("bold 10pt arial")
                .backColor("#120e52")
                .foreColor("#120e52");
        } else {
            sheetShape
                .getRange(1, 1, 1, 3, GC.Spread.Sheets.SheetArea.colHeader)
                .font("bold 10pt arial")
                .backColor("#120e52")
                .foreColor("white");
            sheetShape
                .getRange(1, 0, 1, 1, GC.Spread.Sheets.SheetArea.colHeader)
                .font("bold 10pt arial")
                .backColor("#120e52")
                .foreColor("white")
                .wordWrap(true);
        }
        if (props.eodSheetName === constants.sheetNames.UCOMM) {
            sheetShape
                .getRange(0, 0, 1, coloumnCount, GC.Spread.Sheets.SheetArea.colHeader)
                .font("bold 10pt arial")
                .locked(true)
                .backColor("white")
                .hAlign(GC.Spread.Sheets.HorizontalAlign.center);
            if (count === 1 || count === 3 || count === 4) {
                sheetShape
                    .getRange(0, 0, 1, coloumnCount, GC.Spread.Sheets.SheetArea.colHeader)
                    .foreColor("red");
            } else if (count === 5) {
                sheetShape
                    .getRange(0, 0, 1, coloumnCount, GC.Spread.Sheets.SheetArea.colHeader)
                    .foreColor("green");
            } else {
                sheetShape
                    .getRange(0, 0, 1, coloumnCount, GC.Spread.Sheets.SheetArea.colHeader)
                    .foreColor("black");
            }
        }

        sheetShape
            .getRange(2, 0, 1, coloumnCount, GC.Spread.Sheets.SheetArea.colHeader)
            .font("bold 10pt arial")
            .locked(true)
            .hAlign(GC.Spread.Sheets.HorizontalAlign.center);

        sheetShape.setRowHeight(1, 40, GC.Spread.Sheets.SheetArea.colHeader);
        if (
            props.eodSheetName === constants.sheetNames.EUCARBONCREDIT ||
            props.eodSheetName === constants.sheetNames.USCARBONCREDIT
        ) {
            sheetShape.setRowHeight(0, 40, GC.Spread.Sheets.SheetArea.colHeader);
            sheetShape
                .getRange(0, 0, 1, coloumnCount, GC.Spread.Sheets.SheetArea.colHeader)
                .wordWrap(true);
        }
        //setting borders for bid and mid
        sheetShape
            .getCell(2, 3, GC.Spread.Sheets.SheetArea.colHeader)
            .borderRight(
                new GC.Spread.Sheets.LineBorder("black", GC.Spread.Sheets.LineStyle.medium)
            );
        sheetShape
            .getCell(2, 1, GC.Spread.Sheets.SheetArea.colHeader)
            .borderLeft(
                new GC.Spread.Sheets.LineBorder("black", GC.Spread.Sheets.LineStyle.medium)
            );

        sheetShape
            .getCell(1, 1, GC.Spread.Sheets.SheetArea.colHeader)
            .borderRight(
                new GC.Spread.Sheets.LineBorder("black", GC.Spread.Sheets.LineStyle.medium)
            )
            .borderLeft(
                new GC.Spread.Sheets.LineBorder("black", GC.Spread.Sheets.LineStyle.medium)
            );
        sheetShape
            .getRange(2, 0, 1, 5, GC.Spread.Sheets.SheetArea.colHeader)
            .borderTop(new GC.Spread.Sheets.LineBorder("black", GC.Spread.Sheets.LineStyle.medium))
            .borderBottom(
                new GC.Spread.Sheets.LineBorder("black", GC.Spread.Sheets.LineStyle.medium)
            );
        sheetShape
            .getRange(1, 0, 1, 5, GC.Spread.Sheets.SheetArea.colHeader)
            .borderTop(new GC.Spread.Sheets.LineBorder("black", GC.Spread.Sheets.LineStyle.thin))
            .borderRight(new GC.Spread.Sheets.LineBorder("black", GC.Spread.Sheets.LineStyle.thin));
        sheetShape
            .getCell(2, 4, GC.Spread.Sheets.SheetArea.colHeader)
            .borderRight(new GC.Spread.Sheets.LineBorder("black", GC.Spread.Sheets.LineStyle.thin));
    };
    const setOutlineBorder = (sheet) => {
        const headerRange = sheet.getRange(0, 0, sheet.getRowCount(1), sheet.getColumnCount(), 1);
        const sheetRange = sheet.getRange(0, 0, sheet.getRowCount(), sheet.getColumnCount());

        //set broder top left right border for headre area
        headerRange.setBorder(
            new GC.Spread.Sheets.LineBorder("black", GC.Spread.Sheets.LineStyle.double),
            { left: true, right: true, top: true, bottom: true }
        );
        //set the bottom left right border for sheet Area
        sheetRange.setBorder(
            new GC.Spread.Sheets.LineBorder("black", GC.Spread.Sheets.LineStyle.double),
            { left: true, right: true, bottom: true }
        );
    };
    function midFormulaPropFn(props, sheetShape, index) {
        if (props.midFormula) {
            if (
                sheetShape.getValue(index, 1, GC.Spread.Sheets.SheetArea.viewport) === "" ||
                sheetShape.getValue(index, 2, GC.Spread.Sheets.SheetArea.viewport) === ""
            ) {
                const value =
                    sheetShape.getValue(index, 1, GC.Spread.Sheets.SheetArea.viewport) +
                    sheetShape.getValue(index, 2, GC.Spread.Sheets.SheetArea.viewport);
                sheetShape.setValue(index, 3, value, GC.Spread.Sheets.SheetArea.viewport);
                sheetShape
                    .getRange(`D${index + 1}`)
                    .locked(true)
                    .foreColor("green")
                    .hAlign(GC.Spread.Sheets.HorizontalAlign.center)
                    .borderRight(
                        new GC.Spread.Sheets.LineBorder("black", GC.Spread.Sheets.LineStyle.thin)
                    )
                    .formatter("0.00");
            } else {
                if (props.eodSheetName !== constants.sheetNames.PHYSICALEVENINGNUMBERS) {
                    sheetShape.setFormula(
                        index,
                        3,
                        "IFERROR(AVERAGE(B" + (index + 1) + ":C" + (index + 1) + ")," + '""' + ")",
                        GC.Spread.Sheets.SheetArea.viewport
                    );
                }
                sheetShape
                    .getRange(`D${index + 1}`)
                    .locked(true)
                    .foreColor("green")
                    .hAlign(GC.Spread.Sheets.HorizontalAlign.center)
                    .borderRight(
                        new GC.Spread.Sheets.LineBorder("black", GC.Spread.Sheets.LineStyle.thin)
                    )
                    .formatter("0.00");
            }
        } else {
            if (props.eodSheetName === constants.sheetNames.UCOMM) {
                sheetShape.setFormula(
                    index,
                    3,
                    `C${index + 1}-B${index + 1}`,
                    GC.Spread.Sheets.SheetArea.viewport
                );
            } else {
                sheetShape.setFormula(
                    index,
                    3,
                    "IFERROR(AVERAGE(B" + (index + 1) + ":C" + (index + 1) + ")," + '""' + ")",
                    GC.Spread.Sheets.SheetArea.viewport
                );
            }

            //formatting mid thing and coloring it
            sheetShape
                .getRange(`D${index + 1}`)
                .locked(false)
                .foreColor("green")
                .hAlign(GC.Spread.Sheets.HorizontalAlign.center)
                .borderRight(
                    new GC.Spread.Sheets.LineBorder("black", GC.Spread.Sheets.LineStyle.thin)
                )
                .formatter("0.00");
        }
    }
    const initWorkBook = (spread) => {
        count++;

        let coloumnCount =
            props.eodSheetName === constants.sheetNames.EUCARBONCREDIT ||
            props.eodSheetName === constants.sheetNames.USCARBONCREDIT
                ? Object.keys(props.eodSheetData.sheet1[0]).length
                : Object.keys(props.eodSheetData.sheet1[0]).length + 1;
        if (props.eodSheetName === constants.sheetNames.EODUSMETHANOL && count === 5) {
            coloumnCount = 3;
        }
        if (
            props.eodSheetName === constants.sheetNames.PHYSICALEVENINGNUMBERS &&
            (count === 2 || count === 3)
        ) {
            coloumnCount = 3;
        }

        if (props.eodSheetName === constants.sheetNames.PAPEREVENINGNUMBERS && count === 11) {
            nonRedPmeSheet = spread.getSheetFromName(constants.sheetNames.NONREDPME);
        }
        if (props.eodSheetName === constants.sheetNames.PAPEREVENINGNUMBERS && count === 12) {
            nonRedSmeSheet = spread.getSheetFromName(constants.sheetNames.NONREDSME);
        }
        if (props.eodSheetName === constants.sheetNames.EODUSMETHANOL && count === 3) {
            T2MethanolFOB = spread.getSheetFromName(constants.sheetNames.T2METHANOLFOB);
        }
        if (props.eodSheetName === constants.sheetNames.EODUSMETHANOL && count === 5) {
            usVsEurEth = spread.getSheetFromName("US VS EURETH");
        }

        if (props.eodSheetName === constants.sheetNames.EODUSMETHANOL && count === 4) {
            fobHouston = spread.getSheetFromName(constants.sheetNames.FOBHOUSTON);
        }

        if (props.eodSheetName === constants.sheetNames.PHYSICALEVENINGNUMBERS && count === 1) {
            phyEvengSheet = spread.getSheetFromName(constants.sheetNames.PHYSICALEVENINGEUBIOFUELS);
        }
        if (props.eodSheetName === constants.sheetNames.PHYSICALEVENINGNUMBERS && count === 3) {
            pmeDiscountSheet = spread.getSheetFromName(constants.sheetNames.PMEOVERFME);
        }

        if (props.eodSheetName === constants.sheetNames.UCOMM) {
            ucommSheetOne =
                ucommSheetOne === null
                    ? spread.getSheetFromName(props.eodSheetData.sheet1_datapoint_name)
                    : ucommSheetOne;
            ucommSheetTwo =
                ucommSheetTwo === null
                    ? spread.getSheetFromName(props.eodSheetData.sheet2_datapoint_name)
                    : ucommSheetTwo;
            ucommSheetThree =
                ucommSheetThree === null
                    ? spread.getSheetFromName(props.eodSheetData.sheet3_datapoint_name)
                    : ucommSheetThree;
            ucommSheetFour =
                ucommSheetFour === null
                    ? spread.getSheetFromName(props.eodSheetData.sheet4_datapoint_name)
                    : ucommSheetFour;

            ucommSheetFive =
                ucommSheetFive === null
                    ? spread.getSheetFromName(props.eodSheetData.sheet5_datapoint_name)
                    : ucommSheetFive;
        }

        spread.bind(GC.Spread.Sheets.Events.ValueChanged, function xyz(event, args) {
            const sheet = spread.getSheetFromName(args.sheetName);

            applyFormulas(sheet, args, args.row, args.col);
        });
        spread.bind(GC.Spread.Sheets.Events.EditChange, function xyz(event, args) {
            const sheet = spread.getSheetFromName(args.sheetName);

            applyFormulas(sheet, args, args.row, args.col);
        });

        spread.bind(GC.Spread.Sheets.Events.RangeChanged, function xyz(event, args, action) {
            const sheet = spread.getSheetFromName(args.sheetName);
            for (const changedCell of args.changedCells) {
                if (
                    (sheet.name() === constants.sheetNames.NONREDPME && changedCell.col === 1) ||
                    (sheet.name() === constants.sheetNames.NONREDSME && changedCell.col === 1)
                ) {
                    sheet.setTag(changedCell.row, 1, "false");
                }
            }
            if (args.action === 2 || args.action === 3 || args.action === 0 || args.action === 1) {
                args.changedCells.forEach((item) => {
                    applyFormulas(sheet, args, item.row, item.col);
                });
            }
        });

        spread.bind(GC.Spread.Sheets.Events.ClipboardPasted, function xyz(sender, args) {
            const sheet = spread.getSheetFromName(args.sheetName);
            for (
                let index = args.cellRange.row;
                index < args.cellRange.row + args.cellRange.rowCount;
                index++
            ) {
                if (
                    (sheet.name() === constants.sheetNames.NONREDPME && args.cellRange.col === 1) ||
                    (sheet.name() === constants.sheetNames.NONREDSME && args.cellRange.col === 1)
                ) {
                    sheet.setTag(index, 1, "false");
                } else if (
                    (sheet.name() === constants.sheetNames.T2METHANOLFOB &&
                        (args.cellRange.col === 1 || args.cellRange.col === 2)) ||
                    (sheet.name() === constants.sheetNames.FOBHOUSTON &&
                        (args.cellRange.col === 1 || args.cellRange.col === 2))
                ) {
                    sheet.setTag(index, args.cellRange.col, "false");
                }
                applyFormulas(sheet, args, index, args.cellRange.col);
            }
        });

        const sheetShape = spread.getActiveSheet();

        spreadArr.push(spread);
        const spreadArray = [...spreadArr];
        spread.refresh();
        setSpreadArr(spreadArray);

        if (height >= 1500 && height < 2000) {
            spread.sheets.forEach((sheet) => sheet.zoom(2));
        }
        if (height >= 2000) {
            spread.sheets.forEach((sheet) => sheet.zoom(3));
        }

        SheetOptions(spread, sheetShape, coloumnCount);

        //setting headers and their values and background colors
        sheetShape.suspendPaint();
        sheetShape.setRowCount(3, GC.Spread.Sheets.SheetArea.colHeader);

        //adding spans to coloumns headers of the sheet
        addSpans(sheetShape, coloumnCount);

        //setting values to the coloumn headers
        sheetShape.setValue(0, 0, sheetShape.name(), GC.Spread.Sheets.SheetArea.colHeader);

        if (
            props.eodSheetName === constants.sheetNames.EUCARBONCREDIT ||
            props.eodSheetName === constants.sheetNames.USCARBONCREDIT
        ) {
            sheetShape.setValue(
                1,
                0,
                props.eodSheetData[`sheet${count}_datapoint`],
                GC.Spread.Sheets.SheetArea.colHeader
            );
        } else {
            sheetShape.setValue(
                1,
                1,
                props.eodSheetData[`sheet${count}_datapoint`],
                GC.Spread.Sheets.SheetArea.colHeader
            );
        }

        addHeaders(sheetShape, count);

        //setting flag image at the last coloumn

        if (
            props.eodSheetName !== constants.sheetNames.EUCARBONCREDIT &&
            props.eodSheetName !== constants.sheetNames.USCARBONCREDIT
        ) {
            setFlagImage(sheetShape, coloumnCount, count);
        }

        setOutlineBorder(sheetShape);

        formatSheet(sheetShape, coloumnCount);

        //setting with of coloumns
        if (
            window.innerHeight < 1500 &&
            props.eodSheetName !== constants.sheetNames.PHYSICALEVENINGNUMBERS &&
            props.eodSheetName !== constants.sheetNames.EUCARBONCREDIT &&
            props.eodSheetName !== constants.sheetNames.USCARBONCREDIT
        ) {
            sheetShape.setColumnWidth(0, sheetWidth / 5);
            sheetShape.setColumnWidth(1, sheetWidth / 5);
            sheetShape.setColumnWidth(2, sheetWidth / 5);
            sheetShape.setColumnWidth(3, sheetWidth / 5);
        }

        for (let index = 0; index < sheetShape.getRowCount(); index++) {
            //formatting expiry date and coloring it
            if (index % 2 === 0) {
                sheetShape
                    .getRange(index, 0, 1, 1, GC.Spread.Sheets.SheetArea.viewport)
                    .backColor("lightblue")
                    .wordWrap(true);
            }
            sheetShape
                .getRange(index, 0, 1, 1, GC.Spread.Sheets.SheetArea.viewport)
                .font("bold 10pt arial")
                .hAlign(GC.Spread.Sheets.HorizontalAlign.right);
            sheetShape
                .getRange(`A${index + 1}`)
                .borderRight(
                    new GC.Spread.Sheets.LineBorder("black", GC.Spread.Sheets.LineStyle.thin)
                );

            if (
                props.eodSheetName === constants.sheetNames.PHYSICALEVENINGNUMBERS ||
                props.eodSheetName === constants.sheetNames.EUCARBONCREDIT ||
                props.eodSheetName === constants.sheetNames.USCARBONCREDIT
            ) {
                sheetShape
                    .getRange(index, 0, 1, 1, GC.Spread.Sheets.SheetArea.viewport)
                    .font("bold 10pt arial")
                    .locked(true)
                    .hAlign(GC.Spread.Sheets.HorizontalAlign.center);
            }

            if (sheetShape.getValue(index, 1, GC.Spread.Sheets.SheetArea.viewport) === "") {
                sheetShape.setValue(index, 1, 0, GC.Spread.Sheets.SheetArea.viewport);
            }
            if (sheetShape.getValue(index, 2, GC.Spread.Sheets.SheetArea.viewport) === "") {
                sheetShape.setValue(index, 2, 0, GC.Spread.Sheets.SheetArea.viewport);
            }

            if (
                props.eodSheetName !== constants.sheetNames.PAPEREVENINGNUMBERS &&
                props.eodSheetName !== constants.sheetNames.PHYSICALEVENINGNUMBERS
            ) {
                sheetShape
                    .getRange(`E${index + 1}`)
                    .locked(true)
                    .hAlign(GC.Spread.Sheets.HorizontalAlign.center)
                    .borderRight(
                        new GC.Spread.Sheets.LineBorder("black", GC.Spread.Sheets.LineStyle.thin)
                    )
                    .formatter("0.00");

                if (props.eodSheetName === constants.sheetNames.EODUSMETHANOL && count === 5) {
                    usVsEurEth.setFormula(
                        index,
                        1,
                        "IF(AND(" +
                            T2MethanolFOB.getValue(index, 3, GC.Spread.Sheets.SheetArea.viewport) +
                            ">0," +
                            fobHouston.getValue(index, 3, GC.Spread.Sheets.SheetArea.viewport) +
                            ">0)," +
                            fobHouston.getValue(index, 3, GC.Spread.Sheets.SheetArea.viewport) +
                            "-" +
                            T2MethanolFOB.getValue(index, 3, GC.Spread.Sheets.SheetArea.viewport) +
                            ",0)",
                        GC.Spread.Sheets.SheetArea.viewport
                    );
                }
                midFormulaPropFn(props, sheetShape, index);
            } else {
                sheetShape
                    .getRange(`E${index + 1}`)
                    .locked(false)
                    .hAlign(GC.Spread.Sheets.HorizontalAlign.center)
                    .borderRight(
                        new GC.Spread.Sheets.LineBorder("black", GC.Spread.Sheets.LineStyle.thin)
                    )
                    .formatter("0.00");

                if (
                    sheetShape.name() === constants.sheetNames.NONREDPME ||
                    sheetShape.name() === constants.sheetNames.NONREDSME
                ) {
                    sheetShape.setTag(index, 1, "true");
                }

                sheetShape
                    .getRange(`D${index + 1}`)
                    .locked(true)
                    .foreColor("green")
                    .hAlign(GC.Spread.Sheets.HorizontalAlign.center)
                    .borderRight(
                        new GC.Spread.Sheets.LineBorder("black", GC.Spread.Sheets.LineStyle.thin)
                    )
                    .formatter("0.00");

                if (sheetShape.getValue(index, 3, GC.Spread.Sheets.SheetArea.viewport) === "") {
                    sheetShape.setValue(index, 3, 0, GC.Spread.Sheets.SheetArea.viewport);
                }
                if (sheetShape.getValue(index, 4, GC.Spread.Sheets.SheetArea.viewport) === "") {
                    sheetShape.setValue(index, 4, 0, GC.Spread.Sheets.SheetArea.viewport);
                }
            }
            if (
                sheetShape.name() === constants.sheetNames.T2METHANOLFOB ||
                sheetShape.name() === constants.sheetNames.FOBHOUSTON
            ) {
                sheetShape.setTag(index, 1, "true");
                sheetShape.setTag(index, 2, "true");
            }

            //alligning "bid and offer" to center
            sheetShape
                .getRange(`C${index + 1}`)
                .locked(false)
                .hAlign(GC.Spread.Sheets.HorizontalAlign.center)
                .formatter("0.00");
            if (props.eodSheetName === constants.sheetNames.PHYSICALEVENINGNUMBERS) {
                sheetShape
                    .getRange(`C${index + 1}`)
                    .locked(false)
                    .foreColor("green");
            }

            sheetShape
                .getRange(`B${index + 1}`)
                .locked(false)
                .hAlign(GC.Spread.Sheets.HorizontalAlign.center)
                .formatter("0.00");
            if (
                props.eodSheetName === constants.sheetNames.PHYSICALEVENINGNUMBERS &&
                (sheetShape.name() === constants.sheetNames.SMEPREMFME ||
                    sheetShape.name() === constants.sheetNames.PMEOVERFME)
            ) {
                sheetShape
                    .getRange(`B${index + 1}`)
                    .locked(false)
                    .foreColor("green");
            }
            //formatting expiry date
            if (
                props.eodSheetName !== constants.sheetNames.PHYSICALEVENINGNUMBERS &&
                props.eodSheetName !== constants.sheetNames.EUCARBONCREDIT &&
                props.eodSheetName !== constants.sheetNames.USCARBONCREDIT
            ) {
                sheetShape.setFormatter(index, 0, "MMM-YY");
            }
        }

        sheetShape.resumePaint();
    };

    const old = GC.Spread.Sheets.CellTypes.ColumnHeader.prototype.paint;
    GC.Spread.Sheets.CellTypes.ColumnHeader.prototype.paint = function paint(
        ctx,
        value,
        xyz,
        yza,
        wxy,
        hij,
        style,
        context
    ) {
        context.visualState = GC.Spread.Sheets.VisualState.normal;
        if (context.row === 1) {
            style.backColor = "#120e52";
        }
        if (context.row === 2) {
            style.backColor = "white";
        }

        old.apply(this, arguments);
    };

    const headerHeight = document.getElementById("headerContainer").clientHeight;
    let sheetsHeight = 0;
    if (
        props.eodSheetName === constants.sheetNames.EUETHANOLEVENINGNUMBERS ||
        props.eodSheetName === constants.sheetNames.PAPEREVENINGNUMBERS ||
        props.eodSheetName === constants.sheetNames.PHYSICALEVENINGNUMBERS ||
        props.eodSheetName === constants.sheetNames.EODUSMETHANOL ||
        props.eodSheetName === constants.sheetNames.UCOMM
    ) {
        sheetsHeight = sheetsHeight + (height - headerHeight - (height * 48) / 100);
    } else {
        sheetsHeight = sheetsHeight + (height - headerHeight - (height * 40) / 100);
    }

    const SheetArray = ((sheetCount) => {
        const arr = [];
        let bioFuelWidth;
        for (let index = 1; index <= sheetCount; index++) {
            if (props.eodSheetName === constants.sheetNames.PHYSICALEVENINGNUMBERS) {
                if (
                    props.eodSheetData[`sheet${index}_datapoint_name`] ===
                    constants.sheetNames.PHYSICALEVENINGEUBIOFUELS
                ) {
                    bioFuelWidth = 1.5 * sheetWidth;
                } else if (
                    props.eodSheetData[`sheet${index}_datapoint_name`] ===
                        constants.sheetNames.PMEOVERFME ||
                    props.eodSheetData[`sheet${index}_datapoint_name`] ===
                        constants.sheetNames.SMEPREMFME
                ) {
                    bioFuelWidth = sheetWidth / 1.1;
                }

                arr.push(
                    <div style={{ marign: "5px" }}>
                        <SpreadSheets
                            hostStyle={{
                                position: "relative",
                                width: bioFuelWidth,
                                height: sheetsHeight,
                            }}
                            workbookInitialized={(spread) => initWorkBook(spread)}
                        >
                            <Worksheet
                                name={props.eodSheetData[`sheet${index}_datapoint_name`]}
                                dataSource={props.eodSheetData[`sheet${index}`]}
                            ></Worksheet>
                        </SpreadSheets>
                    </div>
                );
            } else {
                arr.push(
                    <div style={{ marign: "5px" }}>
                        <SpreadSheets
                            hostStyle={{
                                position: "relative",
                                width:
                                    props.eodSheetName === constants.sheetNames.EUCARBONCREDIT ||
                                    props.eodSheetName === constants.sheetNames.USCARBONCREDIT
                                        ? sheetWidth * 0.8
                                        : sheetWidth,
                                height: sheetsHeight,
                            }}
                            workbookInitialized={(spread) => initWorkBook(spread)}
                        >
                            <Worksheet
                                name={props.eodSheetData[`sheet${index}_datapoint_name`]}
                                dataSource={props.eodSheetData[`sheet${index}`]}
                            ></Worksheet>
                        </SpreadSheets>
                    </div>
                );
            }
        }
        return arr;
    })(sheetCount);
    return (
        <>
            {flag ? (
                <div
                    style={{
                        float: "left",
                        display: "flex",
                        marginTop: "-30px",
                        height:
                            props.eodSheetName === constants.sheetNames.EUCARBONCREDIT ||
                            props.eodSheetName === constants.sheetNames.USCARBONCREDIT
                                ? "98%"
                                : "120%",
                        width: "100%",
                        overflowX: "auto",
                        overflowY: "hidden",
                        whiteSpace: "nowrap",
                    }}
                >
                    {SheetArray}
                </div>
            ) : (
                <div>
                    <img
                        src={require("../images/loader.svg").default}
                        style={{
                            alignContent: "center",
                            display: "block",
                            marginLeft: "auto",
                            marginRight: "auto",
                            width: "50%",
                        }}
                        alt=""
                    />
                </div>
            )}
        </>
    );
};
